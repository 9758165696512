@import "@/theme.scss";












.noauth {
  color: #fff;
}
